import * as React from "react"
import {Box, Button, Grid, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {StaticImage} from "gatsby-plugin-image";
import Container from "@mui/material/Container";
import {sectionPadding, textMaxWidth} from "../../theme";
import {graphql, Link} from "gatsby";
import {Bouwnummer, Bouwtype} from "../../graphqlTypes";
import Situatie from "../../components/situatie";
import BouwtypeHeader from "../../components/bouwtype/header";
import Plattegronden from "../../components/bouwtype/plattegronden";
import Fotos from "../../components/bouwtype/fotos";
import GevelaanzichtBlok4 from "../../components/gevelaanzichten/blok4";
import GevelaanzichtBlok6 from "../../components/gevelaanzichten/blok6";

interface Props {
    data: PageQueryData
}
export default ({data}: Props) => {
    return (
        <article>
            <BouwtypeHeader
                title={'Blok 4 & 6'}
                bouwtypes={data.allBouwtype.nodes}
                bouwnummers={data.allBouwnummer.nodes}
            />
            <Box
                sx={{
                    paddingTop: {xs: sectionPadding, md: 0},
                    paddingBottom: sectionPadding
                }}
                paddingY={sectionPadding}
            >
                <Container>
                    <Box
                        maxWidth={textMaxWidth}
                    >
                        <Typography
                            paragraph
                            color={'primary.main'}
                            fontWeight={'medium'}
                        >
                            Blok 4 beslaat een fraai en karaktervol rijtje drie-onder-één-kapwoningen. In Blok 6 sieren straks 5 ruime rijwoningen. De in totaal 8 woningen variëren van 94 tot 108m2 woonoppervlak. Elke woning beschikt over een dakkapel aan de voorkant voor extra ruimte op de verdieping, waarbij bouwnummer 20 met een topgevel beschikt over een extra ruime slaapkamer.
                        </Typography>
                        <Typography
                            paragraph
                            color={'primary.main'}
                            fontWeight={'medium'}
                        >
                            Behoudens bouwnummer 9 hebben de woningen in Blok 4 en 6 het kenmerk levensloopbestendig. Hiermee bestaat de mogelijkheid tot slapen op de begane grond.
                        </Typography>
                    </Box>
                </Container>
            </Box>
            <Box>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <StaticImage src={'../../images/impressies/Interieur-BNR-10.jpg'} alt={'interieur bouwnummer 4'}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper
                                sx={{
                                    backgroundColor: 'secondary.main',
                                    padding: 4,
                                    height: '100%'
                                }}

                            >
                                <Typography
                                    variant={'h4'}
                                    color={'common.white'}
                                >
                                    Kenmerken
                                </Typography>
                                <Typography
                                    color={'common.white'}
                                >
                                    <Box
                                        component={'ul'}
                                    >
                                        <li>
                                            Mogelijkheid tot slapen op de begane grond
                                        </li>
                                        <li>
                                            Beukmaat 6.00m. M.u.v. bouwnr. 9 deze heeft een beukmaat van 5.40m
                                        </li>
                                        <li>
                                            Woonoppervlakte variërend van 94 tot 108m2
                                        </li>
                                        <li>
                                            Perceel variërend van 108 tot 198m2
                                        </li>
                                        <li>
                                            Bouwnummer 8 en 10 hebben een parkeerplaats op eigen terrein
                                        </li>
                                        <li>
                                            Ruime woonkamer en lichte, open keuken
                                        </li>
                                        <li>
                                            Dubbele openslaande deuren naar de tuin
                                        </li>
                                        <li>
                                            2 (slaap-)kamers en badkamer op de eerste verdieping
                                        </li>
                                        <li>
                                            Bergzolder op de tweede verdieping
                                        </li>
                                        <li>
                                            Tuin inclusief eigen berging
                                        </li>
                                        <li>
                                            Voorzien van warmtepomp en zonnepanelen
                                        </li>
                                    </Box>
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Plattegronden bouwtypes={data.allBouwtype.nodes} />
            <Box>
                <Container>
                    <Box
                        sx={{
                            paddingY: sectionPadding*2,
                            backgroundColor: 'secondary.light',
                        }}
                        textAlign={'center'}
                    >
                        <Box
                            maxWidth={textMaxWidth}
                            marginX={'auto'}
                        >
                            <Typography variant={'h2'}>
                                Uiterst stijlvol wonen
                            </Typography>
                            <Typography paragraph>
                                Het open karakter geeft het gevoel alles met de indeling te kunnen doen. De openslaande deuren met grote raampartijen zorgen voor een licht woongedeelte met vrij zicht op de tuin.
                            </Typography>
                            <Typography paragraph>
                                Ook boven is het de ultieme beloning als u op zoek bent naar meer woonvrijheid. U krijgt twee royale slaapkamers en een ruime badkamer op de verdieping. De dakkapel zorgt voor extra licht en ruimte, waarbij de topgevel van bouwnummer 20 voor nóg meer ruimte zorgt. De tweede verdieping is voorzien van een bergzolder.
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Fotos bouwtypes={data.allBouwtype.nodes} />
            <Box
                paddingY={sectionPadding}
            >
                <Container>
                    <Typography
                        variant={'h2'}
                        textAlign={'center'}
                    >
                        Gevelaanzichten
                    </Typography>
                    <Grid
                        container
                        spacing={3}
                        alignItems={'stretch'}
                    >
                        <Grid
                            item
                            xs={12}
                            md={5}
                        >
                            <GevelaanzichtBlok4 />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={7}
                            display={'flex'}
                            flexDirection={'column'}
                        >
                            <GevelaanzichtBlok6 />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box
                paddingY={sectionPadding}
            >
                <Typography
                    variant={'h2'}
                    textAlign={'center'}
                >
                    Situatie Daverhof
                </Typography>
                <Container>
                    <Situatie bouwnummers={data.allBouwnummer.nodes.filter(bouwnummer => bouwnummer.fields.coordinaten)} />
                </Container>
            </Box>
            <Box
                paddingY={sectionPadding}
                textAlign={'center'}
            >
                <Typography
                    variant={'h2'}
                    textAlign={'center'}
                >
                    Bekijk meer woningen
                </Typography>
                <Button
                    component={Link}
                    to={'/woningen/blok1-en-5'}
                    variant={'contained'}
                    sx={{
                        marginX: 1
                    }}
                >
                    Blok 1 + 5
                </Button>
                <Button
                    component={Link}
                    to={'/woningen/blok2-en-3'}
                    variant={'contained'}
                    sx={{
                        marginX: 1
                    }}
                >
                    Blok 2 + 3
                </Button>
            </Box>
        </article>
    )
}

interface PageQueryData {
    allBouwnummer: {
        nodes: Bouwnummer[]
    }
    allBouwtype: {
        nodes: Bouwtype[]
    }
}

export const query = graphql`
    query {
        allBouwnummer(filter: {bouwtype: {algemeen: {omschrijving: {in: ["Blok 4", "Blok 6"]}}}}) {
            nodes {
                id
                ...BouwnummerArea
            }
        }
        allBouwtype(filter: {algemeen: {omschrijving: {in: ["Blok 4", "Blok 6"]}}}) {
            nodes {
                id
                ...BouwtypeHeader
                ...BouwtypePlattegronden
                ...BouwtypeSpecificaties
            }
        }
    }
`
